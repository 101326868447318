/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-04-25 13:27:15
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-25 16:25:07
 */
import service from '@/utils/request'

const API_RUL = {
  // 查询
  LIST: '/menuPermission/whiteList/page',
  ADD: '/menuPermission/whiteList/add',
  UPDATE: '/menuPermission/whiteList/update',
  DELETE: '/menuPermission/whiteList/delete',
  DETAIL: 'menuPermission/whiteList/detail'
}

// 列表数据查询
export const getList = (pramas: any) =>
  service.request({
    method: 'post',
    url: API_RUL.LIST,
    data: pramas
  })
// 新增白名单
export const getAdd = (pramas: any) =>
  service.request({
    method: 'post',
    url: API_RUL.ADD,
    data: pramas
  })
// 修改白名单
export const getEdit = (pramas: any) =>
  service.request({
    method: 'post',
    url: API_RUL.UPDATE,
    data: pramas
  })
// 删除白名单
export const getDelete = (pramas: any) =>
  service.request({
    method: 'post',
    url: API_RUL.DELETE,
    data: pramas
  })
// 白名单详情
export const getDetail = (pramas: any) =>
  service.request({
    method: 'post',
    url: API_RUL.DETAIL,
    data: pramas
  })
