
import { defineComponent, reactive, toRefs, onMounted, shallowRef, shallowReactive } from 'vue'
import { ElMessage } from 'element-plus'
import { getAdd, getEdit, getDetail } from './api'
import { typesOption } from './config'
interface Form {
  enabled: number;
  apiAddress: string;
  applicationType: number;
  description: string;
}
export default defineComponent({
  props: {
    modelValue: Boolean,
    title: String,
    rowData: Object
  },
  meits: ['update:modelValue'],
  setup(props, { emit }) {
    const ruleForm = shallowRef<any>('')
    const data = reactive({
      formData: shallowReactive<Partial<Form>>({
        enabled: 0,
        description: '',
        apiAddress: ''
      }),
      visible: props.modelValue
    })
    const rules = {
      enabled: [{ required: true, message: '必填', trigger: 'blur' }],
      apiAddress: [{ required: true, message: '必填', trigger: 'blur' }],
      applicationType: [{ required: true, message: '必填', trigger: 'change' }]
    }
    const handleCloseDialog = () => {
      emit('update:modelValue', false)
      emit('close-dialog', false)
    }
    function getDetailApi () {
      getDetail({ id: props.rowData.id }).then(res => {
        data.formData = res.data
      })
    }
    // 提交表单
    function submitDialog() {
      ruleForm.value.validate((valid: boolean) => {
        if (valid) {
          // 勾选参数

          const params: any = { ...data.formData }
          let methodFn = getAdd
          if (props.rowData.id) {
            params.id = props.rowData.id
            methodFn = getEdit // 修改方法
          }
          methodFn(params).then((res: any) => {
            ElMessage.success(res.msg)
            handleCloseDialog()
            emit('refreshList')
          })
        }
      })
    }
    onMounted(() => {
      props.rowData.id && getDetailApi()
    })
    return {
      ...toRefs(data),
      typesOption,
      ruleForm,
      rules,
      submitDialog,
      handleCloseDialog
    }
  }
})
